import React from "react";

import horizontalLoader from "~/assets/animations/horizontal_loading.gif";

const HorizontalLoader = () => {
  return (
    <img
      src={horizontalLoader}
      className="h-50 w-50 object-contain"
      alt="Loading"
    />
  );
};

export default HorizontalLoader;
